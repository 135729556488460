import axios from 'axios';

export const sendMessage = (data, successFunc, errFunc) => {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://backend.websolveinfo.com/api/send-message',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios.request(config)
      .then((response) => {
        successFunc(response.data);
      })
      .catch((error) => {
        errFunc(error);
      });
}