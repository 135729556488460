import './modal.css'
import { useState } from 'react';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {FaTimes} from "react-icons/fa"
import { sendMessage } from '../utility/apiCalls';

const MyModal =({closeModal}) =>{

    const [developmentType, setDevelopmentType] = useState("Web Development");
    const [designType, setDesignType] = useState("Graphic Design");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    function onFormSubmit(e){
        e.preventDefault();
        setLoading(true);
        let data = JSON.stringify({
          "development": developmentType,
          "design": designType,
          "name": name,
          "email": email,
          "phone": phone,
          "message": message
        });
        sendMessage(data, (data) => {
            if(data.success){
                toast(data.message);
                closeModal();
            }else if(data && data.message){
                toast(data.message, {type: "error"});
                setLoading(false);
            }else{
                toast("Sorry, Something went wrong!", {type: "error"});
                setLoading(false);
            }
        }, (err)=> {
            console.log(err);
            toast("Sorry, Something went wrong!", {type: "error"});
            setLoading(false);
        })
    }


    return(
        <>
            <div className="modal_wrapper" >
                <div className="modal_box">
                    <h1 className='modal_header'>Your Requirement</h1>
                   <form className='modal_form' onSubmit={onFormSubmit}>
                    <div className='row'>
                        <div className='col-lg-6 col.md-6 mb-3'>
                            <div className='form-group'>
                                <label htmlFor="Name" className='form-label'>Development</label>
                                <select value={developmentType} className='form-select' onChange={e => setDevelopmentType(e.target.value)}>
                                    <option>Web Development</option>
                                    <option>Mobile App Development</option>
                                    <option>Web App Development</option>
                                    <option>WordPress Development</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-6 col.md-6 mb-3'>
                            <div className='form-group'>
                                <label htmlFor="Name" className='form-label'>Design</label>
                                <select value={designType} className='form-select' onChange={e => setDesignType(e.target.value)}>
                                    <option>Graphics Design</option>
                                    <option>Logo Design</option>
                                    <option>Flyer Design</option>
                                    <option>Brochure Design</option>
                                    <option>3D Design</option>

                                </select>
                            </div>
                        </div>
                        <div className='col-lg-6 col.md-6 mb-3'>
                            <div className='form-group'>
                                <label htmlFor="Name" className='form-label'>Name</label>
                                <input value={name} className='form-control' placeholder='Name' onChange={(e) => setName(e.target.value)} required/>
                            </div>
                        </div>
                        <div className='col-lg-6 col.md-6 mb-3'>
                            <div className='form-group'>
                                <label htmlFor="Name" className='form-label'>Email</label>
                                <input 
                                    value={email} 
                                    className='form-control' 
                                    type='email' 
                                    placeholder='Email' 
                                    onChange={e => setEmail(e.target.value)} 
                                    required
                                />
                            </div>
                        </div>
                        <div className='col-lg-6 col.md-6 mb-3'>
                            <div className='form-group'>
                                <label htmlFor="Name" className='form-label'>Phone</label>
                                <input 
                                    value={phone} 
                                    className='form-control' 
                                    type='tel'
                                    placeholder='Phone'
                                    onChange={e => setPhone(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className='col-lg-6 col.md-6 mb-3'>
                            <div className='form-group'>
                                <label htmlFor="Name" className='form-label'>Message</label>
                                <textarea 
                                    value={message} 
                                    placeholder='Message...' 
                                    className='form-control' 
                                    rows={1} 
                                    onChange={e => setMessage(e.target.value)}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                        
                    { loading ? <div className="loader"></div> : <button type='submit' className='modal_contact_btn'>Submit</button>}
                    </form>
                    <button onClick={closeModal} className='modal_btn'><FaTimes/></button>
                </div>
            </div>
        </>
    )
};
export default MyModal;