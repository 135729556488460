import './SwiperHome.css'


// import Swiper core and required modules

import { Swiper, SwiperSlide, } from 'swiper/react';
import { Autoplay, Controller, Navigation, Pagination,EffectFade,} from 'swiper';

import React, { useState } from 'react';
import MyModal from './ShowModal';
// import{Loop} from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


const SwiperHome = () => {

  const [showModal, setShowModal] = useState(false);
    
  const closeModal = () => {
    document.body.style.overflow = 'unset';
    setShowModal(false)
  };

  return (
    <>
    <div style={{position: "relative", zIndex:"100"}}>
      {showModal && <MyModal  closeModal={closeModal}/> } 
    </div>
    <Swiper modules={[Navigation,Controller,Pagination,Autoplay,EffectFade]}
    spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      
      >
        
      <SwiperSlide>
        <img alt='logo' src='/assets/images/home_bg.png'className='swiper_img'/>
        <div className='slogan_box' >
            <h1>We are Websolve<span>info</span></h1>
            <p>Destination for all your web needs. Our flexibility,<br/>and brevity makes us stand out</p>
            <div>
              <button onClick={()=>{
                document.body.style.overflow = 'hidden';
                setShowModal(true);
                }} className='modal_contact_btn'>Contact with us</button>
            </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img alt='logo' src='/assets/images/web-bg.png'className='swiper_img'/>
        <div className='slogan_box' >
            <h1>Web <span>Development</span></h1>
            <p>As per your needs <br/> we develop custom  web applications with quality</p>
            <div>
              <button onClick={()=>{
                document.body.style.overflow = 'hidden';
                setShowModal(true);
                }} className='modal_contact_btn'>Contact with us</button>
            </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img alt='logo' src='/assets/images/mobile_app.png'className='swiper_img'/>
        <div className='slogan_box' >
            <h1>Mobile App<span>Development</span></h1>
            <p>Destination for all your web needs. Our flexibility,<br/>and brevity makes us stand out</p>
            <div>
              <button onClick={()=>{
                document.body.style.overflow = 'hidden';
                setShowModal(true);
                }} className='modal_contact_btn'>Contact with us</button>
            </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img alt='logo' src='/assets/images/wordpress_bg.png'className='swiper_img'/>
        <div className='slogan_box' >
            <h1>Wordpress<span>Development</span></h1>
            <p>Theme integration. HTML to wordpress template. <br/> Ready online woocommerce store.</p>
            <div>
              <button onClick={()=>{
                document.body.style.overflow = 'hidden';
                setShowModal(true);
                }} className='modal_contact_btn'>Contact with us</button>
            </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img alt='logo' src='/assets/images/ui_ux_bg.png'className='swiper_img'/>
        <div className='slogan_box' >
            <h1>UI/UX<span>Design</span></h1>
            <p>No matter how complex your task is,<br/> our creative team completes it with their skills</p>
            <div>
              <button onClick={()=>{
                document.body.style.overflow = 'hidden';
                setShowModal(true);
                }} className='modal_contact_btn'>Contact with us</button>
            </div>
        </div>
      </SwiperSlide>

    </Swiper>
    </>
    
  );
};

export default SwiperHome;