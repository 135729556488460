import React from "react";
import Header from "../Header";
import Footer from './../Footer';
import './Service.css'



function Service(){
    return(
        <div>
            <Header />
            
            <section className="service service_first">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-9">
                            <div className="service_text">
                                <h2>MOBILE APPLICATION</h2>
                                <p>We develop high stability, secured and performance rich oriented mobile applications with scalable interface in: Android app development; iOS app development and Cross-platform/Hybrid app development-Flutter</p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="img_box_service">
                                <img alt="service" src="./assets/images/service_mobile-01.png" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="service md_service_bg" id="services">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-3">
                            <div className="img_box_service">
                                <img alt="service" src="./assets/images/webapp_service-01.png" className="img-fluid"/>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="service_text">
                                <h2>WEBSITE DEVELOPMENT</h2>
                                <p>We believe your website is the natural extension of your brand’s story that sets the tone for your online presence. So we help build customized, mobile-ready and search engine optimized websites that help you meet your business objectives and support your digital marketing campaigns.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="service">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-9 py-5">
                            <div className="service_text">
                                <h2>UI / UX DESIGN</h2>
                                <p>We create creative visual design with a clickable prototype that fulfills both your user needs and business goals. We work with you to produce unique and customized websites and become an extension of your team who try to understand your goals, rather to just executes strategies blindly.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 py-5">
                            <div className="img_box_service">
                                <img alt="service" src="./assets/images/ui_ux_service-01.png" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    )
}

export default Service;



                



                