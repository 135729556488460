import React from 'react'
import Header from '../Header';
import Footer from '../Footer';
import './Technology.css'

function Technology() {
  return (
    <div>
        <Header/>
        <section className='techno_part' id='techno_websolve'>
            <div className='container'>
                <lottie-player src="https://assets4.lottiefiles.com/packages/lf20_WfunLX.json"  background="transparent"  speed="1"    loop  autoplay></lottie-player>
                <div className='py-5 text-center'><h2>Coming Soon</h2></div>
            </div>
        </section>
        <Footer/>
    </div>
  )
}

export default Technology;