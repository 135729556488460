import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import './About.css'


function About(){
    return(
        <div>
            <Header />
            <section className="about" id="aobut_part">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="section-title">
                            <h2>WHO ARE WE?</h2>
                        </div>
                        <div className="col-lg-6 about_para">
                            <p> <strong>We are Websolve.</strong> sounds like an interesting and dynamic team that is passionate about web development. With more than seven members, we likely have a diverse range of skills and expertise within our team, allowing we to tackle complex projects and deliver high-quality results.</p>
                            <p>
                            As a web development team, our work likely involves creating, designing, and maintaining websites and web applications for clients across various industries. This may include developing front-end and back-end functionalities, optimizing user experience, and ensuring the security and reliability of our clients' websites.
                            </p>
                        </div>
                        <div className="col-lg-6 p-3">
                            <div className="team_img_box"></div>
                            {/* <img className="img-fluid" src="assets/images/team.jpg"/> */}
                        </div>
                    </div>
                </div>
            </section>
            <section className="vision_box">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="visionDetailSBox">
                                <div className="topVsBox">
                                    <h2>Vision</h2>
                                </div>
                                <p>At Websolveinfo, our vision is a powerful call to action, a compass that guides our decisions and fuels our passion for creating a better world. Through empowering individuals, creating equitable opportunities, catalyzing impact, nurturing global connectivity, and inspiring a legacy of change, we strive to bring our vision to life. Together, let us embark on this journey to build a future of boundless possibilities, where every individual can thrive, and our planet flourishes for generations to come.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="visionDetailSBox">
                                <div className="topVsBox">
                                    <h2>Mission</h2>
                                </div>
                                <p>At Websolveinfo, our mission to empower change is a call to action, a commitment to creating a better future for all. Through fostering empowerment, promoting equality, driving innovation, and collaborating for impact, we strive to make a positive and lasting difference in the lives of individuals and communities worldwide. Join us on this journey as we work together to build a brighter, more inclusive, and sustainable world. Together, we can create the change we want to see.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="visionDetailSBox">
                                <div className="topVsBox">
                                    <h2>Goal</h2>
                                </div>
                                <p>At Websolveinfo, our goal is more than a mere aspiration; it is a driving force that propels us forward. Through empowering individuals, advancing sustainable development, strengthening communities, promoting innovation and entrepreneurship, and advocating for social justice, we strive to create a meaningful and lasting impact. Join us as we work together towards transforming lives, shaping a better future, and leaving a legacy of positive change for generations to come.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default About;
