import React from 'react'
import Header from '../Header';
import Footer from '../Footer'
import './Home.css'
import SwiperHome from './SwiperHome';
// import Count from './Counter';
// import Modal from './Modal';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import * as Icon from 'react-bootstrap-Icons'
import { 
  BsFillDisplayFill,
  BsPhoneFill,
  BsWordpress,
  BsFillBrushFill,
  BsFillEmojiHeartEyesFill,
  BsFillFileRichtextFill,
  BsHeadset,BsServer,
  BsPcDisplayHorizontal,
  BsXDiamond,BsSnow3,
  BsLink45Deg,
  BsPlus
} from "react-icons/bs";
import { MdGroups2 } from "react-icons/md";
import BsAccordion from './BsAccordion';
import { BiEnvelope,BiSolidPhoneCall} from "react-icons/bi"
import LogoSwiper from './LogoSwiper';
import TestiMonial from '../TestiMonial';

import { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sendMessage } from '../utility/apiCalls';



function Home() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  function onFormSubmit(e){
    e.preventDefault();
    setLoading(true);
    let data = JSON.stringify({
      "name": name,
      "email": email,
      // "phone": "1234567890",
      "subject": subject,
      "message": message
    });
    sendMessage(data, (data) => {
        if(data.success){
            toast(data.message);
            setEmail("");
            setMessage("");
            setSubject("");
            setName("");
            setLoading(false);
        }else if(data && data.message){
            toast(data.message, {type: "error"});
            setLoading(false);
        }else{
            toast("Sorry, Something went wrong!", {type: "error"});
            setLoading(false);
        }
    }, (err)=> {
        console.log(err);
        toast("Sorry, Something went wrong!", {type: "error"});
        setLoading(false);
    })
}

  return (
        <div className='section'>
          <Header />
          <SwiperHome/>
          <section className='weProvide'>
            <div className='circle'>
              <img alt='design' src='/assets/images/Ele_1-02.png'/>
              <img src='/assets/images/Ele_2-02.png'/>
            </div>
            <h1 className='home_heading'>WHAT WE PROVIDE</h1>
            <div className='container '>
              <div className="col-xl-12 d-flex align-items-stretch justify-content-center">
                <div className="icon-boxes d-flex flex-column justify-content-center">
                  <div className="row g-3">
                    <div className="col-md-6 icon-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                      <div className="icon_box_info">
                          <BsFillDisplayFill/>
                          <h4>Web Application</h4>
                          <div className="icon_box_info_para">
                              <p>A web application is a software program that runs on web browsers and is accessible through the internet.</p>
                          </div>
                        </div>
                        {/* <Count/> */}
                    </div>
                    <div className="col-md-6 icon-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                      <div className="icon_box_info">
                        <BsPhoneFill/>
                          <h4>Mobile Application</h4>
                          <div className="icon_box_info_para">
                              <p>Mobile applications, also known as mobile apps, are software programs designed specifically for mobile devices.</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 icon-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                        <div className="icon_box_info">
                         <BsFillBrushFill/>
                          <h4>UX/UI Desgin</h4>
                          <div className="icon_box_info_para">
                              <p>UI (user interface) and UX (user experience) design are essential components of building any digital product, whether it's a web pplication or                    mobile app.</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 icon-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="400">
                        <div className="icon_box_info">
                          <BsWordpress/>
                            <i className="bi bi-wordpress"></i>
                            <h4>Wordpress</h4>
                            <div className="icon_box_info_para">
                                <p>WordPress is a popular and widely-used content management system (CMS) that allows users to create and manage websites without needing extensive technical knowledge or programming skills.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </section>
        
          <section id="counts" className="counts">
            <div className='line_move'>
              <img alt='design' src='assets/images/count_bg1-03.png'/>
            </div>
            <div className="container aos-init aos-animate" data-aos="fade-up">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="count-box">
                    <BsFillEmojiHeartEyesFill/>
                    <span data-purecounter-start="0" data-purecounter-end="5" data-purecounter-duration="0" className="purecounter">5</span>
                    <p>Happy Clients</p>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
                  <div className="count-box">
                    <BsFillFileRichtextFill/>
                    <span data-purecounter-start="0" data-purecounter-end="11" data-purecounter-duration="0" className="purecounter">11</span>
                    <p>Projects</p>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
                  <div className="count-box">
                    <BsHeadset/>
                    <span data-purecounter-start="0" data-purecounter-end="24" data-purecounter-duration="0" className="purecounter">24</span>
                    <p>Hours Of Support</p>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
                  <div className="count-box">
                    <MdGroups2/>
                    <span data-purecounter-start="0" data-purecounter-end="7" data-purecounter-duration="0" className="purecounter">7</span>
                    <p>Hard Workers</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="expertise" className="services section-bg ">
            <div className="container aos-init aos-animate" data-aos="fade-up">
              <div className="section-title">
                <h2>expertise</h2>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="icon-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                    <BsPcDisplayHorizontal/>
                    <div className='text_box'>
                      <h4><a href="#">Front-end</a></h4>
                      <p>Html / Css / Javascript </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mt-4 mt-md-0">
                  <div className="icon-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                    <BsServer/>
                    <div className='text_box'>
                      <h4><a href="#">Back-end</a></h4>
                      <p>Node Js / Php / Python</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mt-4 mt-md-0">
                  <div className="icon-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                    <BsXDiamond/>
                    <div className='text_box'>
                      <h4><a href="#">Front-end Frame Works</a></h4>
                      <p>Bootstrap / Angular / React / Node Js</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mt-4 mt-md-0">
                  <div className="icon-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="400">
                    <BsSnow3/>
                    <div className='text_box'>
                      <h4><a href="#">Back-end Frame Works</a></h4>
                      <p>Django / Laravel / Flask / Express Js / Next Js</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="portfolio" className="portfolio">
            <div className="container aos-init aos-animate" data-aos="fade-up">
              <div className="section-title">
                <h2>Projects</h2>
              </div>
              <ul className="nav nav-tabs justify-centent-center text-center" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">All</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">App</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Web</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="graphic-tab" data-bs-toggle="tab" data-bs-target="#graphic" type="button" role="tab" aria-controls="contact" aria-selected="false">Graphics</button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <div className='container'>
                    <div className='row'>
                      <div className='col-lg-3 col-md-6 tab_box'>
                        <img alt='design' src='assets/images/pinkhub-app.png' className='img-fluid'/>
                        <div className="portfolio-info">
                          <h4>Card 2</h4>
                          <p>Card</p>
                          <div className="portfolio-links">
                            <a href="assets/images/pinkhub-app.png" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Card 2">
                              <BsPlus/>
                            </a>
                            <a href="#" title="More Details">
                              <BsLink45Deg/>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-6 tab_box'>
                        <img alt='design' src='assets/images/op-banner.png' className='img-fluid'/>
                        <div className="portfolio-info">
                          <h4>Card 2</h4>
                          <p>Card</p>
                          <div className="portfolio-links">
                            <a href="assets/images/op-banner.png" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Card 2">
                              <BsPlus/>
                            </a>
                            <a href="#" title="More Details">
                              <BsLink45Deg/>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-6 tab_box'>
                        <img alt='design' src='assets/images/ursroom-web.png' className='img-fluid'/>
                        <div className="portfolio-info">
                          <h4>Card 2</h4>
                          <p>Card</p>
                          <div className="portfolio-links">
                            <a href="assets/images/ursroom-web.png" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Card 2">
                              <BsPlus/>
                            </a>
                            <a href="#" title="More Details">
                              <BsLink45Deg/>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-6 tab_box'>
                        <img alt='design' src='assets/images/Pinkhub-web.png' className='img-fluid'/>
                        <div className="portfolio-info">
                          <h4>Card 2</h4>
                          <p>Card</p>
                          <div className="portfolio-links">
                            <a href="assets/images/Pinkhub-web.png" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Card 2">
                              <BsPlus/>
                            </a>
                            <a href="#" title="More Details">
                              <BsLink45Deg/>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-6 tab_box'>
                        <img alt='design' src='assets/images/speed-magazine.png' className='img-fluid'/>
                        <div className="portfolio-info">
                          <h4>Card 2</h4>
                          <p>Card</p>
                          <div className="portfolio-links">
                            <a href="assets/images/speed-magazine.png" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Card 2">
                              <BsPlus/>
                            </a>
                            <a href="#" title="More Details">
                              <BsLink45Deg/>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-6 tab_box'>
                        <img alt='design' src='assets/images/medicure-web.png' className='img-fluid'/>
                        <div className="portfolio-info">
                          <h4>Card 2</h4>
                          <p>Card</p>
                          <div className="portfolio-links">
                            <a href="assets/images/medicure-web.png" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Card 2">
                              <BsPlus/>
                            </a>
                            <a href="#" title="More Details">
                              <BsLink45Deg/>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-6 tab_box'>
                        <img alt='design' src='assets/images/bright-leaflet.png' className='img-fluid'/>
                        <div className="portfolio-info">
                          <h4>Card 2</h4>
                          <p>Card</p>
                          <div className="portfolio-links">
                            <a href="assets/images/bright-leaflet.png" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Card 2">
                              <BsPlus/>
                            </a>
                            <a href="#" title="More Details">
                              <BsLink45Deg/>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-6 tab_box'>
                        <img alt='design' src='assets/images/medicare-app.png' className='img-fluid'/>
                        <div className="portfolio-info">
                          <h4>Card 2</h4>
                          <p>Card</p>
                          <div className="portfolio-links">
                            <a href="assets/images/medicare-app.png" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Card 2">
                              <BsPlus/>
                            </a>
                            <a href="#" title="More Details">
                              <BsLink45Deg/>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-6 tab_box'>
                        <img alt='design' src='assets/images/standyee-design.png' className='img-fluid'/>
                        <div className="portfolio-info">
                          <h4>Card 2</h4>
                          <p>Card</p>
                          <div className="portfolio-links">
                            <a href="assets/images/standyee-design.png" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Card 2">
                              <BsPlus/>
                            </a>
                            <a href="#" title="More Details">
                              <BsLink45Deg/>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                  <div className='container'>
                    <div className='row'>
                      <div className='col-lg-3 col-md-6 tab_box'>
                        <img alt='design' src='assets/images/medicare-app.png' className='img-fluid'/>
                        <div className="portfolio-info">
                          <h4>Card 2</h4>
                          <p>Card</p>
                          <div className="portfolio-links">
                            <a href="assets/images/standyee-design.png" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Card 2">
                              <BsPlus/>
                            </a>
                            <a href="#" title="More Details">
                              <BsLink45Deg/>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-6 tab_box'>
                        <img alt='design' src='assets/images/pinkhub-app.png' className='img-fluid'/>
                        <div className="portfolio-info">
                          <h4>Card 2</h4>
                          <p>Card</p>
                          <div className="portfolio-links">
                            <a href="assets/images/standyee-design.png" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Card 2">
                              <BsPlus/>
                            </a>
                            <a href="#" title="More Details">
                              <BsLink45Deg/>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                  <div className='container'>
                    <div className='row'>
                      <div className='col-lg-3 col-md-6 tab_box'>
                        <img alt='design' src='assets/images/ursroom-web.png' className='img-fluid'/>
                        <div className="portfolio-info">
                          <h4>Card 2</h4>
                          <p>Card</p>
                          <div className="portfolio-links">
                            <a href="assets/images/standyee-design.png" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Card 2">
                              <BsPlus/>
                            </a>
                            <a href="#" title="More Details">
                              <BsLink45Deg/>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-6 tab_box'>
                          <img alt='design' src='assets/images/Pinkhub-web.png' className='img-fluid'/>
                          <div className="portfolio-info">
                          <h4>Card 2</h4>
                          <p>Card</p>
                          <div className="portfolio-links">
                            <a href="assets/images/standyee-design.png" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Card 2">
                              <BsPlus/>
                            </a>
                            <a href="#" title="More Details">
                              <BsLink45Deg/>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-6 tab_box'>
                          <img alt='design' src='assets/images/medicure-web.png' className='img-fluid'/>
                          <div className="portfolio-info">
                            <h4>Card 2</h4>
                            <p>Card</p>
                            <div className="portfolio-links">
                              <a href="assets/images/standyee-design.png" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Card 2">
                                <BsPlus/>
                              </a>
                              <a href="#" title="More Details">
                                <BsLink45Deg/>
                              </a>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="graphic" role="tabpanel" aria-labelledby="graphic-tab">
                  <div className='container'>
                    <div className='row'>
                      <div className='col-lg-3 col-md-6 tab_box'>
                        <img alt='design' src='assets/images/standyee-design.png' className='img-fluid'/>
                        <div className="portfolio-info">
                          <h4>Card 2</h4>
                          <p>Card</p>
                          <div className="portfolio-links">
                            <a href="assets/images/standyee-design.png" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Card 2">
                              <BsPlus/>
                            </a>
                            <a href="#" title="More Details">
                              <BsLink45Deg/>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-6 tab_box'>
                        <img alt='design' src='assets/images/op-banner.png' className='img-fluid'/>
                        <div className="portfolio-info">
                          <h4>Card 2</h4>
                          <p>Card</p>
                          <div className="portfolio-links">
                            <a href="assets/images/standyee-design.png" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Card 2">
                              <BsPlus/>
                            </a>
                            <a href="#" title="More Details">
                              <BsLink45Deg/>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-6 tab_box'>
                        <img alt='design' src='assets/images/speed-magazine.png' className='img-fluid'/>
                        <div className="portfolio-info">
                          <h4>Card 2</h4>
                          <p>Card</p>
                          <div className="portfolio-links">
                            <a href="assets/images/standyee-design.png" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Card 2">
                              <BsPlus/>
                            </a>
                            <a href="#" title="More Details">
                              <BsLink45Deg/>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-6 tab_box'>
                        <img alt='design' src='assets/images/bright-leaflet.png' className='img-fluid'/>
                        <div className="portfolio-info">
                          <h4>Card 2</h4>
                          <p>Card</p>
                          <div className="portfolio-links">
                            <a href="assets/images/standyee-design.png" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Card 2">
                              <BsPlus/>
                            </a>
                            <a href="#" title="More Details">
                              <BsLink45Deg/>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section>
          <section className='logo_part' id='logoPart'>
            <div className='container'>
            <div className="section-title">
                <h2 className='text-dark'>Logo Showcase</h2>
              </div>
              <LogoSwiper/>
            </div>
          </section>
          <section className='testimonial' id='testimonialPart'>
            <div className='container'>
              <div className="section-title">
              <h2>Testimonials</h2>
              <p className="text-center">Our dear customers. Whose faith and trust gives us strength to walk. We are committed to you.</p>
              </div>
              <TestiMonial/>
            </div>
          </section>
          <section id='faQ' className='faQs'>
            <div className='container'>
              <div className="section-title">
                <h2>Faqs</h2>
              </div>
              <div className='row'>
                <BsAccordion/>
                
              </div>
            </div>
          </section>
          <section id="contact" className="contact">
            <div className="container aos-init aos-animate" data-aos="fade-up">
              <div className="section-title">
                <h2>Contact</h2>
              </div>
              <div className="row align-items-center aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="info-box ">
                        <BiEnvelope/>
                        <h3>Email Us</h3>
                        <a href="mailto:wewebsolve@gmail.com">wewebsolve@gmail.com</a> || <a href='mailto:shibnath@websolveinfo.com'>shibnath@websolveinfo.com</a>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="info-box mt-4">
                        <BiSolidPhoneCall/>
                        <h3>Call Us</h3>
                        <a href="tel:+91 8420676255">+91 8420676255</a> || <a href="tel:+91 9874695515">+91 9874695515</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <form role="form" className="contact_us_form" onSubmit={onFormSubmit}>
                    <div className="row">
                      <div className="col form-group">
                        <input 
                          value={name}
                          type="text" 
                          name="name" 
                          className="form-control" 
                          id="name" 
                          placeholder="Your Name"
                          onChange={e => setName(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col form-group">
                        <input
                          value={email}
                          type="email" 
                          className="form-control" 
                          name="email" 
                          id="email" 
                          placeholder="Your Email" 
                          onChange={e => setEmail(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        value={subject}
                        type="text" 
                        className="form-control" 
                        name="subject" 
                        id="subject" 
                        placeholder="Subject" 
                        onChange={e => setSubject(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <textarea 
                        value={message} 
                        className="form-control" 
                        name="message" 
                        rows="5" 
                        placeholder="Message" 
                        onChange={e => setMessage(e.target.value)} 
                      ></textarea>
                    </div>
                    <div className="text-center btn_box">
                      
                      { loading ? <div className="loader"></div> : <button type="submit">Send Message</button>}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
          
          <Footer/>
        </div>
  )
}

export default Home;