import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Controller, Navigation, Pagination,EffectFade,} from 'swiper';
import { BiSolidQuoteAltLeft,BiSolidQuoteAltRight} from "react-icons/bi"

import 'swiper/css';

const TestiMonial = () => {
  return (
    <Swiper modules={[Navigation,Controller,Pagination,Autoplay,EffectFade]}
      spaceBetween={50}
      slidesPerView={3}
      onSlideChange={() => {}}
      onSwiper={(swiper) => {}}
      autoplay
      pagination
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
      }}
    >
      <SwiperSlide>
        <div className="testimonial-wrap">
            <div className="testimonial-item">
                <img src="./assets/testimonial/testimonials-4.jpg" className="img-fluid testimonial-img" alt="webSolveInfoImage"/>
                <h3>Matt Brandon</h3>
                <h4>Freelancer</h4>
                <p>
                <BiSolidQuoteAltLeft/>
                Cooperative and service is 24/7. I'm very happy.
                <BiSolidQuoteAltRight/>
                </p>
            </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="testimonial-item">
            <img src="./assets/testimonial/testimonials-2.jpg" className="img-fluid testimonial-img" alt="webSolveInfoImage"/>
            <h3>Matt Brandon</h3>
            <h4>Freelancer</h4>
            <p>
            <BiSolidQuoteAltLeft/>
            Cooperative and service is 24/7. I'm very happy.
            <BiSolidQuoteAltRight/>
            </p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="testimonial-item">desi
            <img src="./assets/testimonial/testimonials-3.jpg" className="img-fluid testimonial-img" alt="webSolveInfoImage"/>
            <h3>Matt Brandon</h3>
            <h4>Freelancer</h4>
            <p>
            <BiSolidQuoteAltLeft/>
            Cooperative and service is 24/7. I'm very happy.
            <BiSolidQuoteAltRight/>
            </p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="testimonial-item">
            <img src="./assets/testimonial/testimonials-5.jpg" className="img-fluid testimonial-img" alt=""/>
            <h3>Matt Brandon</h3>
            <h4>Freelancer</h4>
            <p>
            <BiSolidQuoteAltLeft/>
            Cooperative and service is 24/7. I'm very happy.
            <BiSolidQuoteAltRight/>
            </p>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default TestiMonial;