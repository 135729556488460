import React from 'react'
import './Footer.css'
import { FaAngleRight } from "react-icons/fa";
import { BiLogoTwitter,BiLogoYoutube,BiLogoFacebookCircle,BiLogoGooglePlus,BiLogoInstagram } from "react-icons/bi";

function Footer() {
  return (
    <div className='footer'>
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 footer-contact">
              <h3 className="logo me-auto">
                <a href="/">
                  <img src="assets/websolve logo-01.png" alt="logo" className="img-fluid"/>
                </a>
              </h3>
              <p>India,West Bengal,K.S.D.Road<br/>Kolkata-700-049
              </p>
            </div>
            <div className="col-lg-2 col-md-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li><FaAngleRight/> <a href="/">Home</a></li>
                <li><FaAngleRight/> <a href="/">About us</a></li>
                <li><FaAngleRight/> <a href="/">Services</a></li>
                <li><FaAngleRight/> <a href="/">Terms of service</a></li>
                <li><FaAngleRight/> <a href="/">Privacy policy</a></li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li><FaAngleRight/><a href="/">Web Design</a></li>
                <li><FaAngleRight/><a href="/">Web Development</a></li>
                <li><FaAngleRight/><a href="/">Product Management</a></li>
                <li><FaAngleRight/><a href="/">Marketing</a></li>
                <li><FaAngleRight/><a href="/">Graphic Design</a></li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-6 footer-newsletter">
              <h4>Join Our Newsletter</h4>
              <p>Your Support make us strong.</p>
              <form action="" method="post" className="footer_subscribe_form">
                <input type="email" name="email" required="" className="form-control"/>
                  <input type="submit" value="Subscribe"/>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="container d-md-flex py-4 bg-dark">
        <div className="me-md-auto text-center text-md-start">
          <div className="copyright">
            © Copyright <strong><span>Websolve</span></strong>. All Rights Reserved
          </div>
          <div className="credits">
              Designed by <a href="mailto:shibnath.jupiter@gmail.com">Shibnath</a>
          </div>
        </div>
        <div className="social-links text-center text-md-end pt-3 pt-md-0">
          <a href="/" className="twitter"><BiLogoTwitter/></a>
          <a href="/" className="facebook"><BiLogoFacebookCircle/></a>
          <a href="/" className="instagram"><BiLogoInstagram/></a>
          <a href="/" className="google-plus"><BiLogoGooglePlus/></a>
          <a href="/" className="linkedin"><BiLogoYoutube/></a>
        </div>
      </div>
    </div>
  )
}

export default Footer;