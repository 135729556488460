import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import './Contact.css'
import { useState } from "react";
import { toast } from 'react-toastify';
import { sendMessage } from '../utility/apiCalls';


function Contact(){
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [loading, setLoading] = useState(false);

    function onFormSubmit(e){
        e.preventDefault();
        setLoading(true);
        let data = JSON.stringify({
          "name": name,
          "email": email,
          "phone": phone,
          "address": address
        });
        
        sendMessage(data, (data) => {
            if(data.success){
                toast(data.message);
                setName("");
                setEmail("");
                setPhone("");
                setAddress("");
                setLoading(false);
            }else if(data && data.message){
                toast(data.message, {type: "error"});
                setLoading(false);
            }else{
                toast("Sorry, Something went wrong!", {type: "error"});
                setLoading(false);
            }
        }, (err)=> {
            console.log(err);
            toast("Sorry, Something went wrong!", {type: "error"});
            setLoading(false);
        })
    }

    return(
        <div>
            <Header />
            <section className="contact_section">
                <div className="container ">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="img_box">
                                <form className="contact_form" onSubmit={onFormSubmit}>
                                    <h1 className="contact_heading">Contact With Us</h1>
                                    <div className="form-group mb-3">
                                        <label className="form-label">Name</label>
                                        <input 
                                            value={name} 
                                            className='form-control'
                                            placeholder="Name"
                                            onChange={(e) => setName(e.target.value)} 
                                            required
                                        />
                                    </div>
                                    
                                    <div className="form-group mb-3">
                                        <label className="form-label">Email</label>
                                        <input 
                                            value={email} 
                                            className='form-control' 
                                            type='email'
                                            placeholder="Email"
                                            onChange={e => setEmail(e.target.value)} 
                                            required
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label">Phone</label>
                                        <input 
                                            value={phone} 
                                            className='form-control' 
                                            type='tel'
                                            placeholder="Phone"
                                            onChange={e => setPhone(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label">Address</label>
                                        <input 
                                            value={address} 
                                            className='form-control'
                                            placeholder="Address"
                                            onChange={e => setAddress(e.target.value)}
                                        />
                                    </div>
                                    { 
                                    loading ? 
                                        <div className="loader"></div> 
                                        : <button type="submit" className="btn modal_contact_btn mt-3">Submit</button>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
        
    )
}

export default Contact;