
import { useRef } from "react";
import React from "react";
import  "./commone.css"
import { Link } from "react-router-dom";
import {FaBars,FaTimes} from "react-icons/fa"






function Header(){
    const navRef = useRef();
    const showNavbar =()=>{
        navRef.current.classList.toggle('manuResponsive');
    }
    
    return(
        
        <div className="header_nav">
            <div className="container d-flex align-items-center justify-content-between">
                <div className="logo_box">
                    <li> <Link to={'/'} ><img src="./assets/websolve logo-01.png" alt="Logo"/></Link> </li>
                </div>
                <button onClick={showNavbar}className="btn close-btn">
                    <FaBars/>
                </button>
            
                <div className="nav_bar" ref={navRef}>
                    <li> <Link to={'/'} className="nav_links">Home</Link> </li>
                    <li> <Link to={'/About'}className="nav_links">About</Link></li>
                    <li><Link to={'/Technology'} className="nav_links">Technology</Link></li>
                    <li><Link to={'/Service'}className="nav_links">Service</Link></li>
                    <li><Link to={'/Contact'}className="nav_links">Contact</Link></li>
                    <button onClick={showNavbar} className="btn close-btn">
                        <FaTimes/>
                    </button>
                </div>
            </div>
        </div>
    )
}
export default Header;
// function Customlink(){
//     const path = window.location.pathname
//     return(
//         <li className={path === to? 'active': ''}>
//             <link to={to}{...props}>{Children}</link>
//         </li>
//     )

// }