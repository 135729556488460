import React from 'react'
import Accordion  from 'react-bootstrap/Accordion';
import './BsAccordion.css'

function BsAccordion() {
  return (
   
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Why to choose us?</Accordion.Header>
        <Accordion.Body>
          <h6>Many reasons that clients trust us for:</h6>
          <p>1.Work on time.</p>
          <p>2.You get the project as you want.</p>
          <p>3.Provide the right solution.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Why trust on us?</Accordion.Header>
        <Accordion.Body>
        <h6>We have some previous works where we got customer appreciation</h6>
          <p>1.Future Lift.</p>
          <p>2.URS Room.</p>
          <p>3.Pinkhub.</p>
          <p>4.Medicure.</p>

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>What services does your company offer?</Accordion.Header>
        <Accordion.Body>
          <h6>We provide you with all kinds of information related to the web</h6>
          <p>Our company offers a wide range of web development services, including website design and development, e-commerce solutions, custom web applications, content management systems, search engine optimization, and website maintenance and support,</p>
        </Accordion.Body>
        </Accordion.Item>
    </Accordion>
   
  )
}

export default BsAccordion;