import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Controller, Navigation, Pagination,EffectFade,} from 'swiper';

// Import Swiper styles
import 'swiper/css';

const LogoSwiper = () => {
  return (
    <Swiper modules={[Navigation,Controller,Pagination,Autoplay,EffectFade]}
      spaceBetween={50}
      slidesPerView={5}
      onSlideChange={() => {}}
      onSwiper={(swiper) => {}}
      navigation={{clickable: true}}
      pagination={{ clickable: true }}
      autoplay
      breakpoints={{
        0: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 4,
        },
      }}
    >
      <SwiperSlide>
        <img alt='logo' src='/assets/logo/logo_1-01.png' className='img-fluid'/>
      </SwiperSlide>
      <SwiperSlide>
        <img alt='logo' src='/assets/logo/logo_2-01.png' className='img-fluid'/>
      </SwiperSlide>
      <SwiperSlide>
        <img alt='logo' src='/assets/logo/logo_3-01.png' className='img-fluid'/>
      </SwiperSlide>
      <SwiperSlide>
        <img alt='logo' src='/assets/logo/logo_4-01.png' className='img-fluid'/>   
      </SwiperSlide>
      <SwiperSlide>
        <img alt='logo' src='/assets/logo/logo_5-01.png' className='img-fluid'/>   
      </SwiperSlide>
      <SwiperSlide>
        <img alt='logo' src='/assets/logo/logo_6-01.png' className='img-fluid'/>   
      </SwiperSlide>
      <SwiperSlide>
        <img alt='logo' src='/assets/logo/logo_7-01.png' className='img-fluid'/>   
      </SwiperSlide>
      <SwiperSlide>
        <img alt='logo' src='/assets/logo/logo_8-01.png' className='img-fluid'/>   
      </SwiperSlide>
      <SwiperSlide>
        <img alt='logo' src='/assets/logo/logo_9-01.png' className='img-fluid'/>   
      </SwiperSlide>
      <SwiperSlide>
        <img alt='logo' src='/assets/logo/logo_10-01.png' className='img-fluid'/>   
      </SwiperSlide>
    </Swiper>
  );
};

export default LogoSwiper;