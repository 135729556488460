
import './App.css';
import Home from './pages/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import About from './pages/About';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';
import Service from './pages/Service';
import Technology from './pages/Technology';
  
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/About' element={<About />}></Route>
        <Route path='/Gallery' element={<Gallery />}></Route>
        <Route path='/Contact' element={<Contact />}></Route>
        <Route path='/Service' element={<Service />}></Route>
        <Route path='/Technology' element={<Technology />}></Route>
      </Routes>
      <ToastContainer/>
    </BrowserRouter>
  );
}

export default App;
